import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from "axios";
import { AppConfig, ServerAPIErrorResponse, ServerAPIResponse, UserLoginResponse } from "./interfaces";
import withReactContent, { ReactSweetAlertOptions } from "sweetalert2-react-content";
import Swal, { SweetAlertResult } from "sweetalert2";
import { store } from "../store/store";
import { setLoggedOnUserProfile } from "../store/loggedOnUserProfile";
import { setAccessToken } from "../store/accessToken";
import { AppPlatform } from "./constants";
import { infoColorLight, secondaryColorLight, infoColorDark, secondaryColorDark, pageBackgroundColorDark, pageBackgroundColorLight, primaryTextColorDark, primaryTextColorLight } from "../layouts/theme";
import { setAppConfig } from "../store/appConfig";
import { getAllCMDBUsers } from "../apiServices/UserManagementApi";
import { getAllWells } from "../apiServices/WellsApi";
import { setCmdbUsers } from "../store/cmdbUsers";
import { setWells } from "../store/wells";

export async function loadAppConfigFromSettingsFile() {
    const dispatch = store.dispatch;
    await fetch("appsettings.json").then<AppConfig>(r => r.json()).then((data) => {
        const appConfig: AppConfig = {
            SERVER_URL: data.SERVER_URL ?? process.env.REACT_APP_SERVER_URL ?? "",
            ENVIRONMENT: data.ENVIRONMENT ?? process.env.REACT_APP_ENVIRONMENT ?? "",
            APP_VERSION: data.APP_VERSION ?? process.env.REACT_APP_VERSION ?? "",
            APP_LAST_UPDATED: Number(data.APP_LAST_UPDATED ?? process.env.REACT_APP_LAST_UPDATED ?? 0) * 1000,
            APP_PLATFORM: data.APP_PLATFORM ?? process.env.REACT_APP_PLATFORM ?? "",
            DARK_MODE: false
        }
        dispatch(setAppConfig(appConfig));
    })
        .catch(function (error: AxiosError<ServerAPIResponse<any>>) {
            console.log(error);
        });
}

export async function callServerAPI<Type>(method: Method, url: string, data: any = null, serverAuthenticationRequired: boolean, responseType: import("axios").ResponseType = 'json'): Promise<AxiosResponse<Type>> {
    return await new Promise(async (resolve, reject) => {
        let accessToken = store.getState().accessToken.value;
        const appConfig = store.getState().appConfig.value;
        let config: AxiosRequestConfig = {}

        // check if server authentication is required and get refresh Accesstoken if it is null or expired
        if (serverAuthenticationRequired && (accessToken == null || new Date(accessToken.expiry_date) < new Date())) {
            if (await callServerRefreshToken(true)) accessToken = store.getState().accessToken.value
        }

        config.headers = { ...config.headers, Authorization: 'Bearer ' + accessToken?.token }
        config.method = method; config.url = appConfig.SERVER_URL + url; config.withCredentials = true; config.data = data
        if (responseType) config.responseType = responseType
        await axios.request(config)
            .then(function (response: AxiosResponse<Type>) {
                resolve(response)
            })
            .catch(function (error: AxiosError<ServerAPIErrorResponse<any>>) {
                console.log(error);
                fireAxiosErrorMySwal(error)
                //reject(error.response)
            });
    });
}

export async function callServerTepngUserLogin(): Promise<AxiosResponse<ServerAPIResponse<UserLoginResponse>>> {
    const dispatch = store.dispatch;
    const appConfig = store.getState().appConfig.value;
    return await new Promise(async (resolve, reject) => {
        var config: AxiosRequestConfig = {}
        config.method = 'POST'; config.data = null; config.url = appConfig.SERVER_URL + '/api/authentication/login-tepng-user'; config.withCredentials = true
        await axios.request(config)
            .then(async function (response: AxiosResponse<ServerAPIResponse<UserLoginResponse>>) {
                dispatch(setLoggedOnUserProfile(response.data.data.user));
                dispatch(setAccessToken(response.data.data.access_token));
                getAllCMDBUsers().then(cmdbusers => dispatch(setCmdbUsers(cmdbusers)))
                getAllWells().then(wells => dispatch(setWells(wells)))
                resolve(response)
            })
            .catch(function (error: AxiosError<ServerAPIResponse<any>>) {
                console.log(error);
                reject(error)
            });
    });
}

export async function callServerTepngUserLogout(): Promise<AxiosResponse<ServerAPIResponse<string>>> {
    const dispatch = store.dispatch;
    const appConfig = store.getState().appConfig.value;
    return await new Promise(async (resolve, reject) => {
        await callServerAPI<ServerAPIResponse<string>>('POST', '/api/authentication/logout-tepng-user', null, true)
            .then(async response => {
                dispatch(setLoggedOnUserProfile(null));
                dispatch(setAccessToken(null));
                let url = new URL(window.location.origin)
                // needed to logout of microsoft - the frond end url needs to be added to Azure App registration Redirect URIs to allow for logout redirect
                if (appConfig.APP_PLATFORM === AppPlatform.Azure) window.location.replace(appConfig.SERVER_URL + '/.auth/logout?post_logout_redirect_uri=' + window.encodeURIComponent(url.href));
                resolve(response) //
            })
            .catch(function (error: AxiosError<ServerAPIErrorResponse<any>>) {
                console.log(error);
                fireAxiosErrorMySwal(error)
            });
    });
}

export async function callServerRefreshToken(redirectToLoginIfFailedRefreshToken: boolean): Promise<boolean> {
    const MySwal = withReactContent(Swal);
    const dispatch = store.dispatch;
    const appConfig = store.getState().appConfig.value;
    return await new Promise(async (resolve, reject) => {
        await axios.post(appConfig.SERVER_URL + '/api/authentication/refresh-tepng-user-access-token', null, { withCredentials: true })
            .then(async function (response: AxiosResponse<ServerAPIResponse<UserLoginResponse>>) {
                dispatch(setLoggedOnUserProfile(response.data.data.user));
                dispatch(setAccessToken(response.data.data.access_token));
                getAllCMDBUsers().then(cmdbusers => dispatch(setCmdbUsers(cmdbusers)))
                getAllWells().then(wells => dispatch(setWells(wells)))
                resolve(true)
            })
            .catch(function (error: AxiosError) {
                if (redirectToLoginIfFailedRefreshToken) {
                    MySwal.fire(alertError("Your session has expired, you will be redirected to login again"));
                    window.location.href = "/login"
                }
                console.log(error);
                resolve(false)
            });
    });
}


export async function fireAxiosErrorMySwal(error: AxiosError<ServerAPIErrorResponse<any>>) {
    if (error?.response?.data instanceof Blob && error?.response?.data?.type && error?.response?.data?.type.toLowerCase().indexOf('json') !== -1) {
        var errorString = JSON.parse(await error.response.data.text());
        showAlertError(errorString.message)
    }
    else {
        if (error?.response == null)
            showAlertError(error.message)
        else if (error?.response?.data?.detail == null)
            showAlertError(error.response.status + ' ' + error.response.statusText)
        else
            showAlertError(error?.response?.data?.detail)
    }
}

export function showAlertError(message: string) {
    const MySwal = withReactContent(Swal);
    MySwal.fire(alertError(message))
}

function alertError(message: string): ReactSweetAlertOptions {
    const appConfig = store.getState().appConfig.value;
    return {
        icon: 'warning',
        title: '',
        html: message,
        showDenyButton: true,
        denyButtonText: 'OK',
        showConfirmButton: false,
        background: appConfig.DARK_MODE ? pageBackgroundColorDark : pageBackgroundColorLight,
        color: appConfig.DARK_MODE ? primaryTextColorDark : primaryTextColorLight,
    }
}
export function showBackDrop(message: string): ReactSweetAlertOptions {
    const appConfig = store.getState().appConfig.value;
    return {
        title: message,
        background: appConfig.DARK_MODE ? pageBackgroundColorDark : pageBackgroundColorLight,
        color: appConfig.DARK_MODE ? primaryTextColorDark : primaryTextColorLight,
        html: '<div style="height:150px;width:150px;margin: 0 auto;"><img style="width: 100px;" src="../../assets/images/loading-icon-red.gif" /><br> <strong> Please Wait... </strong> </div>', showConfirmButton: false, showCancelButton: false, allowOutsideClick: false,
    }
}

export function showConfirmBox(message: string, title: string = 'Confirm', yesButtonText: string = 'Yes', noButtonText: string = 'No'): Promise<SweetAlertResult<any>> {
    const MySwal = withReactContent(Swal);
    const appConfig = store.getState().appConfig.value;
    return MySwal.fire({
        title: `<span style="font-size:16px">${title}</span>`,
        html: message,
        icon: 'question',
        cancelButtonColor: appConfig.DARK_MODE ? infoColorDark : infoColorLight,
        cancelButtonText: noButtonText,
        showCancelButton: true,
        confirmButtonColor: appConfig.DARK_MODE ? secondaryColorDark : secondaryColorLight,
        confirmButtonText: yesButtonText,
        background: appConfig.DARK_MODE ? pageBackgroundColorDark : pageBackgroundColorLight,
        color: appConfig.DARK_MODE ? primaryTextColorDark : primaryTextColorLight,
        iconColor: appConfig.DARK_MODE ? primaryTextColorDark : primaryTextColorLight,
    })
}


export function getNameFromTotalEnergiesEmail(email: string) {
    if (Boolean(email) === false) return ''
    let names = email.toLowerCase().replace("@external.totalenergies.com", '').replace("@totalenergies.com", '').split('.')
    names = names.map(txt => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase())
    return names.join(' ')
}
export function formatDate(date: Date, format: string) {
    let d = new Date(date);

    if (format === 'time') {
        return d.toLocaleTimeString('en-US');
    }
    else if (format === 'date') {
        let y = d.getFullYear();
        let m = d.getMonth();
        let dd = d.getDate();
        let months_of_year = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

        return `${months_of_year[m] + ' ' + dd + ', ' + y}`
        //   return d.toDateString();
    }
    else {
        return d.toDateString() + ' ' + d.toLocaleTimeString('en-US');
    }
}

export function formatDateTimeLocal(date: Date | null | undefined) {
    if (date == null) return ""
    date = new Date(date);
    const day = `${date.getDate() < 10 ? '0' : ''}${date.getDate()}`;
    const month = `${date.getMonth() + 1 < 10 ? '0' : ''}${date.getMonth() + 1}`;
    const year = (date.getFullYear() + '').padStart(4, '0')
    const hour = (date.getHours() + '').padStart(2, '0')
    const min = (date.getMinutes() + '').padStart(2, '0')
    return `${year}-${month}-${day}T${hour}:${min}`;
}
export function formatDateTimeLocalNearestHour(date: Date | null | undefined) {
    if (date == null) return new Date()
    date = new Date(date);
    date.setHours(date.getHours() + 1);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    return date;
}
export function formatShortDateString(dtStr: Date, includeTime = false): string {
    var dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: '2-digit' };
    var timeOptions: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
    var dt = new Date(dtStr)
    return dt.toLocaleDateString('en-GB', dateOptions) + (includeTime ? " " + dt.toLocaleTimeString('en-GB', timeOptions) : '')
}