import { useCallback, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { callServerTepngUserLogin, showBackDrop, fireAxiosErrorMySwal } from '../helpers/utils';
import { AppPlatform, RoutePathNames } from '../helpers/constants';
import { useAppSelector } from '../store/store';
import { Button } from '@mui/material';


const MySwal = withReactContent(Swal);

function LoginTepngUser() {
  const navigate = useNavigate()
  const appConfig = useAppSelector(state => state.appConfig.value);
  let queryParams = new URLSearchParams(window.location.search);
  const azureAuthRedirect = queryParams.get("azureauthredirect") === '1'
  // microsoft login redirect - the frond end url with the /login-tepnguser needs to be added to Azure App Service authentication Allowed external redirect URLs to allow for login redirect
  const login = useCallback(async () => {
    MySwal.fire(showBackDrop("User Sign in ongoing"));
    callServerTepngUserLogin()
      .then(response => {
        MySwal.close();
        const loginRedirectedFromUrl = localStorage.getItem('loginRedirectedFromUrl');
        localStorage.removeItem('loginRedirectedFromUrl');

          navigate(loginRedirectedFromUrl ?? "/" + RoutePathNames.SCENARIO_RUN_HISTORY)


  })
      .catch(function (error) {
        //if this is not a redirect from azure auth then we redirect to azure auth to perform azure AD authentication
        if (!azureAuthRedirect && error?.response?.status === 401) {
          let url = new URL(window.location.origin + '/' + RoutePathNames.LOGIN)
          url.searchParams.append('azureauthredirect', '1') // microsoft login redirect - the frond end url with the /login-tepnguser needs to be added to Azure App Service authentication Allowed external redirect URLs to allow for login redirect
          window.location.replace(appConfig.SERVER_URL + '/.auth/login/aad?post_login_redirect_uri=' + window.encodeURIComponent(url.href));
        }
        else
          fireAxiosErrorMySwal(error)
      })
  }, [navigate, azureAuthRedirect, appConfig.SERVER_URL])

  useEffect(() => {
    // attempt login again if azure has made a redirection
    if (appConfig.APP_PLATFORM === AppPlatform.Azure && azureAuthRedirect)
      login()
  }, [azureAuthRedirect, login, appConfig.APP_PLATFORM]);

  return (
    <div className='w-ull h-screen flex items-start'>
      <div className='relative w-3/5 h-full flex flex-col'>
        <img src='../../assets/images/landingImage.png' alt='' className='w-full h-full object-cover' />
      </div>

      <div className='w-2/5 h-full bg-[#FFFFFF] flex flex-col  justify-center items-center'>
        <div className='flex justify-center items-center '>
          <img src='../../assets/images/logo_totalenergies.png' alt='' className='w-64' />
        </div>
        <div className='text-center'>
          <h1 className='text-5xl font-bold tracking-widest'>OML58 <b className='text-[#CA092C]'>PROP-TSC</b></h1>
          <p className='text-[14px] font-semibold'>Production Optimisation against TNP Curtailment and Storage Constraints</p>
          <br />
          <Button onClick={() => login()} variant='contained' className='w-full h-10 text-xl' color="secondary"><b style={{fontSize:'16px'}}>Login</b></Button>
        </div>

      </div>
    </div>
  )
}

export default LoginTepngUser