import React, { useEffect, useState } from 'react'
import { ScenarioRun, ScenarioRunSummary } from '../helpers/interfaces';
import { deleteScenarioRun, getScenarioRunHistory } from '../apiServices/ScenarioRunApi';
import { Box, Button, Grid, MenuItem, Pagination, Paper, Stack ,Table, TableBody, TableHead, useTheme,} from '@mui/material'
import { formatDate, formatShortDateString, getNameFromTotalEnergiesEmail, showConfirmBox } from '../helpers/utils';
import { Add, RemoveRedEye, Delete } from '@mui/icons-material';
import CreateScenarioRun from '../components/scenarioRun/CreateScenarioRun';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../store/store';
import { emptyScenarioRun, FormEditMode, RoutePathNames } from '../helpers/constants';
import { CustomTextField, StyledTableCell, StyledTableRow } from '../layouts/theme';


function ScenarioRunList() {
    const theme = useTheme();
    const navigate = useNavigate();
    const [scenarioRunSummary, setScenarioRunSummary] = useState<ScenarioRunSummary[]>([])
    const [filteredScenarioRunSummary, setFilteredScenarioRunSummary] = useState<ScenarioRunSummary[]>([])
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [recordsPerPage, setRecordsPerPage] = useState<number>(5);
    const [numOfPages, setNumOfPages] = useState<number>(0);
    const [currentPageRecords, setCurrentPageRecords] = useState<ScenarioRunSummary[]>([])
    const [openScenarioRunForm, setOpenScenarioRunForm] = React.useState(false);
    const [scenarioRunToEdit] = useState<string>("");
    const [scenarioRunEditMode, setScenarioRunEditMode] = React.useState(FormEditMode.CREATE);
const [ , setScenarioRun]= useState<ScenarioRun>(emptyScenarioRun)
    const wells = useAppSelector(state => state.wells.value)


    const handleOpenUserForm = () => {
        const latestUpdatedWell = wells.reduce((a, b) => new Date(a.updated_at) > new Date(b.updated_at) && a.updated_at != null ? a : b)
        var thresholdDate = new Date()
        thresholdDate.setMinutes(thresholdDate.getMinutes() - 1)
        if (new Date(latestUpdatedWell.updated_at) < thresholdDate) {
            showConfirmBox(`Well Instruction Sheet (WIS) was last updated  on ${formatDate(wells[0]?.created_at, 'date')} and  Well Status was last updated on  ${formatDate(latestUpdatedWell.updated_at, 'date')} ${formatDate(latestUpdatedWell.updated_at, 'time')}. Do you want to update the WIS?`, " Update WIS and Well Status?")
                .then((result) => {
                    if (result.isConfirmed)
                        navigate('/' + RoutePathNames.WELLS)
                    else
                        setOpenScenarioRunForm(true);
                        setScenarioRunEditMode(FormEditMode.CREATE);
                })
        }
        else
            setOpenScenarioRunForm(true);
            setScenarioRunEditMode(FormEditMode.CREATE);
    };
    useEffect(() => {
        getScenarioRunHistory().then(scenarioRun => setScenarioRunSummary(scenarioRun.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())))
    }, [])
    useEffect(() => {
        setFilteredScenarioRunSummary(scenarioRunSummary.filter(e =>
            (searchQuery === "" || e.description.toLowerCase().includes(searchQuery.toLowerCase()))
        ));
        setCurrentPage(1);
    }, [searchQuery, scenarioRunSummary])


    useEffect(() => {
        setNumOfPages(Math.ceil(filteredScenarioRunSummary.length / recordsPerPage))
    }, [recordsPerPage, filteredScenarioRunSummary])

    useEffect(() => {
        const lastIndex = currentPage * recordsPerPage;
        const firstIndex = lastIndex - recordsPerPage;
        setCurrentPageRecords(filteredScenarioRunSummary.slice(firstIndex, lastIndex))
    }, [currentPage, recordsPerPage, filteredScenarioRunSummary])



    function deleteRun(id: string) {
        showConfirmBox("Are you sure you want to Delete this Scenario Run?", " Delete Scenario Run")
            .then((result) => {
                if (result.isConfirmed)
                    deleteScenarioRun(id).then(() => setScenarioRunSummary(scenarioRun => scenarioRun.filter(u => u.id !== id)))
            })
    }


    return (
        <Box m="1.0rem 1.0rem">
            <div className=' font-bold   text-lg' style={{color:theme.palette.text.secondary}}>Run History</div>
            <Box>
                <Paper>
                    <Box mt="10px" p='1.0rem' gap="20px">
                        <div className=" flex flex-row ">
                            <div className=' flex w-2/3 '>
                                <CustomTextField size="small" id="outlined-basic" placeholder="Search by Name..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} variant="outlined" sx={{ width: '50%' }} /> &nbsp;

                            </div>
                            <div style={{ flexGrow: 1 }} />

                            <Button color='secondary' variant="contained" style={{ textTransform: 'none', fontSize: 14, }} onClick={handleOpenUserForm}><Add />&nbsp; New Scenario Run</Button>
                        </div>
                        <br />
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <Table >
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell> Sn</StyledTableCell>
                                        <StyledTableCell > Description</StyledTableCell>
                                        <StyledTableCell > Run By</StyledTableCell>
                                        <StyledTableCell > Run Date</StyledTableCell>
                                        <StyledTableCell>Implementation Start</StyledTableCell>
                                        <StyledTableCell >Last Refresh Date</StyledTableCell>
                                        <StyledTableCell>Last Updated By</StyledTableCell>
                                        <StyledTableCell >Action</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {currentPageRecords.map((item, index) => {
                                        return <StyledTableRow key={index}>

                                            <StyledTableCell >{index + 1 + ((currentPage - 1) * recordsPerPage)}</StyledTableCell>
                                            <StyledTableCell >{item.description}</StyledTableCell>
                                            <StyledTableCell >{getNameFromTotalEnergiesEmail(item.runner_email)}</StyledTableCell>
                                            <StyledTableCell >{formatShortDateString(item.run_date, true)}</StyledTableCell>
                                            <StyledTableCell >{formatShortDateString(item.implementation_start_date, true)}</StyledTableCell>
                                            <StyledTableCell>{formatShortDateString(item.last_refresh_date, true)}</StyledTableCell>
                                            <StyledTableCell >{getNameFromTotalEnergiesEmail(item.updated_by)}</StyledTableCell>
                                            <StyledTableCell >
                                                <Button variant='outlined' color='info' size='small' onClick={(e) => { navigate(`/${RoutePathNames.SCENARIO_RUN}/${item.id}`) }}  ><RemoveRedEye />&nbsp; View</Button>&nbsp;&nbsp;
                                                <Button variant='outlined' color='secondary' size='small' onClick={() => { deleteRun(item.id) }}   > <Delete />&nbsp; Delete</Button></StyledTableCell>
                                        </StyledTableRow>
                                    })}

                                    {currentPageRecords.length === 0 &&
                                        <tr>
                                            <StyledTableCell colSpan={7}><p style={{ fontSize: '11px', textAlign: 'center' }}>No record available...</p></StyledTableCell>
                                        </tr>
                                    }
                                </TableBody>
                            </Table>
                        </div>
                        <br />

                        <nav>
                            <div className=" flex flex-row ">
                                <div className=" flex items-start ">
                                    <div className="px-6 py-2">Rows per page</div>&nbsp;
                                    < Grid item xs={12} lg={3} >
                                        <CustomTextField fullWidth id="fullWidth" select variant="outlined" size="small" name="RequestsNumbers" value={recordsPerPage} onChange={(e) => setRecordsPerPage(Number(e.target.value))} >
                                            <MenuItem value="5">5</MenuItem >
                                            <MenuItem value="15">15</MenuItem >
                                            <MenuItem value="25">25</MenuItem >
                                            <MenuItem value="40">40</MenuItem >
                                            <MenuItem value="55">55</MenuItem >
                                            <MenuItem value="85">85</MenuItem >
                                            <MenuItem value="125">125</MenuItem >
                                            <MenuItem value="200">200</MenuItem >
                                            <MenuItem value="255">255</MenuItem >
                                            <MenuItem value="350">350</MenuItem >
                                            <MenuItem value="500">500</MenuItem >


                                        </CustomTextField>
                                    </Grid>
                                </div>
                                <div style={{ flexGrow: 1 }} />
                                <Stack spacing={2}>
                                    <Pagination color="secondary" count={numOfPages} siblingCount={1} boundaryCount={1}
                                        onChange={(event, value) => setCurrentPage(value)} page={currentPage} size="small" classes={{ ul: "PaginationUl" }} />
                                </Stack>

                            </div>
                            <br />


                        </nav>

                        {openScenarioRunForm &&
                            <CreateScenarioRun updateScenarioRun={setScenarioRun} setFormEditMode={setScenarioRunEditMode} formEditMode={scenarioRunEditMode} scenarioRunToEdit={scenarioRunToEdit}  getScenarioRunHistory={getScenarioRunHistory} setScenarioRunSummary={setScenarioRunSummary} scenarioRunSummary={scenarioRunSummary} openScenarioRunForm={openScenarioRunForm} setOpenScenarioRunForm={setOpenScenarioRunForm} />
                        }
                    </Box>
                </Paper>
            </Box>
        </Box>
    )
}

export default ScenarioRunList
