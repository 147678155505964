import { Scenario, Tank, UpdateScenarioDto, Well } from '../../helpers/interfaces'
import { Box, Paper, Table, TableBody, TableHead, Grid, MenuItem, Stack, Pagination, Button, useTheme, } from '@mui/material'
import { CustomTextField, StyledTableCell, StyledTableRow } from '../../layouts/theme';
import { formatDateTimeLocal, formatShortDateString } from '../../helpers/utils';
import StorageProfile from './StorageProfile';
import { Status, WellType } from '../../helpers/constants';
import { useState, useEffect, memo } from 'react';
import { useScenarioContext } from '../../views/ViewScenarioRun';
import React from 'react';
import OilWellsKPI from './analysiskpis/OilWellsKPI';
import GasWellsKPI from './analysiskpis/GASWellsKPI';
import OilReinjectionKPI from './analysiskpis/OilReinjectionKPI';
import CondensateSpikeKPI from './analysiskpis/CondensateSpikeKPI';
import { UpdateScenarioForAnalysis } from '../../apiServices/ScenarioApi';
import { store } from '../../store/store';
import { WaterDrop } from '@mui/icons-material';




interface Props {
    excludedTanks: string
    includedTanks: Tank[]
    displayStorageProfileBarChart: boolean
    setDisplayStorageProfileBarChart: React.Dispatch<React.SetStateAction<boolean>>
}

const gridTemplate = `
"a a b b"
"a a b b"
"a a b b"
"a a c c"
"a a c c"
"a a c c"
"d d e e"
"d d e e"


`
function ScenarioDetails(props: Props) {
    const theme = useTheme();
    const appConfig = store.getState().appConfig.value;
    const { scenarioId, setScenario, scenarioRun, setScenarioRun } = useScenarioContext();
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(60);
    const [numOfPages, setNumOfPages] = useState(0);
    const [currentPageRecords, setCurrentPageRecords] = useState<Well[]>([]);
    const [gascurrentPage, setGasCurrentPage] = useState(1);
    const [gasrecordsPerPage, setGasRecordsPerPage] = useState(60);
    const [gasnumOfPages, setGasNumOfPages] = useState(0);
    const [gascurrentPageRecords, setGasCurrentPageRecords] = useState<Well[]>([]);
    const [openOilWells, setOpenOilWells] = React.useState(false);
    const [openGasWells, setOpenGasWells] = React.useState(false);
    const [openCondensateProfile, setOpenCondensateProfile] = React.useState(false);
    const [openOilReInjection, setOpenOilReInjection,] = React.useState(false);

    const handleModifyOilWells = () => {
        setOpenOilWells(true);
    };
    const handleModifyGasWells = () => {
        setOpenGasWells(true);
    };
    const handleOilReInjection = () => {
        setOpenOilReInjection(true);
    };
    const handleCondensateProfile = () => {
        setOpenCondensateProfile(true);
    };
    function updateScenario(pscenario: Scenario) {
        setScenario(pscenario)
        let analysisDto: UpdateScenarioDto = {
            well_statuses: pscenario.well_statuses,
            oil_reinjection_profiles: pscenario.oil_reinjection_profiles,
            condensate_spike_profiles: pscenario.condensate_spike_profiles
        }
        UpdateScenarioForAnalysis(scenarioRun.id, scenarioId, analysisDto).then(_scenarioRun => {
            setScenarioRun(_scenarioRun)
        })
    }

    useEffect(() => {
        setNumOfPages(Math.ceil(scenarioRun.wells.filter(e => e.type === WellType.OIL).length / recordsPerPage))
    }, [scenarioRun.wells, recordsPerPage])

    useEffect(() => {
        const lastIndex = currentPage * recordsPerPage;
        const firstIndex = lastIndex - recordsPerPage;
        setCurrentPageRecords(scenarioRun.wells.filter(e => e.type === WellType.OIL).slice(firstIndex, lastIndex))
    }, [currentPage, scenarioRun.wells, recordsPerPage])

    useEffect(() => {
        setGasNumOfPages(Math.ceil(scenarioRun.wells.filter(e => e.type === WellType.GAS).length / gasrecordsPerPage))
    }, [scenarioRun.wells, gasrecordsPerPage])

    useEffect(() => {
        const lastIndex = gascurrentPage * gasrecordsPerPage;
        const firstIndex = lastIndex - gasrecordsPerPage;
        setGasCurrentPageRecords(scenarioRun.wells.filter(e => e.type === WellType.GAS).slice(firstIndex, lastIndex))
    }, [gascurrentPage, gasrecordsPerPage, scenarioRun.wells])

    function closedOilWells() {
        let closed_wells = Object.entries(scenarioRun.scenarios[scenarioId].well_statuses).filter(([name, status]) => status === Status.CLOSED && !scenarioRun.wells.filter(e => (e.status === Status.CLOSED)).map(e => e.name).includes(name))
        let closed_oil_wells = closed_wells.filter(([name, status]) => scenarioRun.wells.find(e => e.name === name)?.type === WellType.OIL)
        let list_of_closed_oil_wells = closed_oil_wells.map(([name, status]) => name).join(", ")
        return <StyledTableCell className='tb'>
            {closed_oil_wells.length > 0 ?
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', }}>
                        <span>{list_of_closed_oil_wells.substring(0, list_of_closed_oil_wells.length)}</span>
                    </div>
                </div> : 'No Oil Well Closed'}
        </StyledTableCell>
    }
    function closedGasWells() {
        let closed_wells = Object.entries(scenarioRun.scenarios[scenarioId].well_statuses).filter(([name, status]) => status === Status.CLOSED && !scenarioRun.wells.filter(e => (e.status === Status.CLOSED)).map(e => e.name).includes(name))

        let closed_gas_wells = closed_wells.filter(([name, status]) => scenarioRun.wells.find(e => e.name === name)?.type === WellType.GAS)

        let list_of_closed_gas_wells = closed_gas_wells.map(([name, status]) => name).join(", ")

        return <StyledTableCell className='tb'  >
            {closed_gas_wells.length > 0 ?
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', }}>
                        <span>{list_of_closed_gas_wells.substring(0, list_of_closed_gas_wells.length)}</span>
                    </div>
                </div> : 'No Gas Well Closed'}
        </StyledTableCell>

    }
    return (
        <>
            <div className='flex  w-full  p-5 font-bold'>

                <Button variant='contained' color='secondary' onClick={handleModifyOilWells}><b>Edit Oil Wells</b></Button>&nbsp;
                <Button variant='contained' color='secondary' onClick={handleModifyGasWells}><b>Edit Gas Wells</b></Button>&nbsp;
                <Button variant='contained' color='secondary' onClick={handleCondensateProfile}><b>Edit Condensate Profile</b></Button>&nbsp;
                <Button variant='contained' color='secondary' onClick={handleOilReInjection}><b>Edit Oil Re-Injection</b></Button>&nbsp;

            </div>
            <Box width={'100%'} height={'100%'} display={'grid'} gap={'0.5rem'}
                sx={{
                    gridTemplateAreas: gridTemplate,
                    gridTemplateColumns: 'repeat(4, minmax(290px, 1fr))',
                    gridTemplateRows: '50px 1fr 30px'
                }}
            >
                <Box sx={{ gridArea: 'a' }} p={'.5rem'}>
                    <Paper >
                        <Table >
                            <TableHead >
                                <StyledTableRow><StyledTableCell colSpan={2} sx={{ background: 'white', color: `${theme.palette.secondary.main}`, fontWeight: 'bold' }} >Summary </StyledTableCell></StyledTableRow>
                                <StyledTableRow><StyledTableCell colSpan={2} >{scenarioRun.scenarios[scenarioId].description} </StyledTableCell></StyledTableRow>
                            </TableHead>
                            <TableBody>

                                <StyledTableRow >
                                    <StyledTableCell style={{ fontWeight: 'bold'}}>Closed Oil Wells</StyledTableCell>
                                    {closedOilWells()}
                                </StyledTableRow>
                                <StyledTableRow >
                                    <StyledTableCell style={{ fontWeight: 'bold'}}>Closed Gas Wells</StyledTableCell>
                                    {closedGasWells()}
                                </StyledTableRow>
                                <StyledTableRow >
                                    <StyledTableCell style={{ fontWeight: 'bold'}}>Gas Produced (MMSm3/d)</StyledTableCell>
                                    <StyledTableCell className='tb' style={{ color: appConfig.DARK_MODE ? '#0288D1' : '#0288D1', fontWeight: 'bold' }}>{(scenarioRun.scenarios[scenarioId].produced_gas / 1000).toFixed(3)}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow >
                                    <StyledTableCell style={{ fontWeight: 'bold'}}>Condensate Produced (bbls/d) </StyledTableCell>
                                    <StyledTableCell className='tb' style={{ color: appConfig.DARK_MODE ? '#0288D1' : '#0288D1', fontWeight: 'bold' }}>{parseFloat(scenarioRun.scenarios[scenarioId].produced_condensate?.toFixed(1))?.toLocaleString('en')}</StyledTableCell>
                                </StyledTableRow>

                                <StyledTableRow >
                                    <StyledTableCell style={{ fontWeight: 'bold'}}>Oil Produced  (bbls/d)</StyledTableCell>
                                    <StyledTableCell className='tb' style={{ color: appConfig.DARK_MODE ? '#0288D1' : '#0288D1', fontWeight: 'bold' }} >{parseFloat(scenarioRun.scenarios[scenarioId].produced_oil?.toFixed(1))?.toLocaleString('en')}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow >
                                    <StyledTableCell style={{ fontWeight: 'bold'}}>Condensate Oil ratio</StyledTableCell>
                                    <StyledTableCell className='tb' style={{ color: scenarioRun.scenarios[scenarioId].condensate_oil_ratio <= scenarioRun.maximum_condensate_oil_ratio ? ` ${theme.palette.success.main} ` : ` ${theme.palette.secondary.main} `, fontWeight: 'bold' }}>{(scenarioRun.scenarios[scenarioId].condensate_oil_ratio * 100)?.toFixed(0)}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow >
                                    <StyledTableCell style={{ fontWeight: 'bold'}}>Spike into OUR (bbls/d)</StyledTableCell>
                                    <StyledTableCell className='tb' style={{ color: appConfig.DARK_MODE ? '#0288D1' : '#0288D1', fontWeight: 'bold' }} >{parseFloat(scenarioRun.scenarios[scenarioId].condensate_spike?.toFixed(1))?.toLocaleString('en')}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow >
                                    <StyledTableCell style={{ fontWeight: 'bold'}}>Oil reinjected (bbls/d)</StyledTableCell>
                                    <StyledTableCell className='tb' style={{ color: appConfig.DARK_MODE ? '#0288D1' : '#0288D1', fontWeight: 'bold' }}>{parseFloat(scenarioRun.scenarios[scenarioId].oil_reinjected?.toFixed(1))?.toLocaleString('en')}</StyledTableCell>
                                </StyledTableRow>

                                <StyledTableRow >
                                    <StyledTableCell style={{ fontWeight: 'bold'}}>Condensate filling rate send to OFS (bbls/d)</StyledTableCell>
                                    <StyledTableCell className='tb' style={{ fontWeight: 'bold' }}>{parseFloat(scenarioRun.scenarios[scenarioId].condensate_filling_rate?.toFixed(1))?.toLocaleString('en')}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow >
                                    <StyledTableCell style={{ fontWeight: 'bold'}}>Total liquid Filling rate at OFS (bbls/d)</StyledTableCell>
                                    <StyledTableCell className='tb' style={{ fontWeight: 'bold' }} >{parseFloat(scenarioRun.scenarios[scenarioId].total_liquid_filling_rate?.toFixed(1))?.toLocaleString('en')}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow >
                                    <StyledTableCell style={{ fontWeight: 'bold'}}>Total Liquid produced (Excluding reinjection) (bbls/d)</StyledTableCell>
                                    <StyledTableCell className='tb' style={{ fontWeight: 'bold' }}>{(scenarioRun.scenarios[scenarioId].total_liquid_produced)?.toFixed(1)}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow >
                                    <StyledTableCell style={{ fontWeight: 'bold'}}>Water export / Injection (bbls/d)</StyledTableCell>
                                    <StyledTableCell className='tb' style={{ fontWeight: 'bold' }}>{parseFloat(scenarioRun.scenarios[scenarioId].water_export_and_injection?.toFixed(1))?.toLocaleString('en')}</StyledTableCell>
                                </StyledTableRow>

                                <StyledTableRow >
                                    <StyledTableCell style={{ fontWeight: 'bold'}}>Gas for the MP Comp.(MMSm3/d)</StyledTableCell>
                                    <StyledTableCell className='tb' style={{ fontWeight: 'bold' }} >{((scenarioRun.scenarios[scenarioId].gas_for_mp_compressor/ 1000).toFixed(3))}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow >
                                    <StyledTableCell style={{ fontWeight: 'bold'}}>Gas for LP Compressor (MMSm3/d)</StyledTableCell>
                                    <StyledTableCell className='tb' style={{ fontWeight: 'bold' }}>{((scenarioRun.scenarios[scenarioId].gas_for_lp_compressor/ 1000).toFixed(3))}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow >
                                    <StyledTableCell style={{ fontWeight: 'bold'}}>Gas for HP Compressor (MMSm3/d)</StyledTableCell>
                                    <StyledTableCell className='tb' style={{ fontWeight: 'bold' }}>{((scenarioRun.scenarios[scenarioId].gas_for_hp_compressor/ 1000).toFixed(3))} </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow >
                                    <StyledTableCell style={{ fontWeight: 'bold'}}>Storage space gain or lost (bbls/d)</StyledTableCell>
                                    <StyledTableCell className='tb' style={{ color: scenarioRun.scenarios[scenarioId].storage_space_gain_or_lost > 0 ? ` ${theme.palette.success.main} ` : ` ${theme.palette.secondary.main} `, fontWeight: 'bold' }} >{parseFloat(scenarioRun.scenarios[scenarioId].storage_space_gain_or_lost?.toFixed(1))?.toLocaleString('en')}</StyledTableCell>
                                </StyledTableRow>

                                <StyledTableRow >
                                    <StyledTableCell style={{ fontWeight: 'bold' }}>HP compressor running </StyledTableCell>
                                    <StyledTableCell className='tb' style={{ fontWeight: 'bold' }}>
                                        <span style={{ color: scenarioRun.min_no_hp_compressors_to_run <= scenarioRun.scenarios[scenarioId].hp_compressors_running ? ` ${theme.palette.success.main} ` : ` ${theme.palette.secondary.main} ` }}>
                                            {scenarioRun.scenarios[scenarioId].hp_compressors_running} HP
                                        </span>
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow >
                                    <StyledTableCell style={{ fontWeight: 'bold' }}>MP+LP compressors running</StyledTableCell>
                                    <StyledTableCell className='tb' style={{ fontWeight: 'bold' }}>
                                        <span style={{ color: scenarioRun.min_no_lp_compressors_to_run <= scenarioRun.scenarios[scenarioId].lp_compressors_running ? ` ${theme.palette.success.main} ` : ` ${theme.palette.secondary.main} ` }}>{scenarioRun.scenarios[scenarioId].lp_compressors_running}LP</span>
                                        &nbsp;&nbsp;+ &nbsp;&nbsp;
                                        <span style={{ color: scenarioRun.min_no_mp_compressors_to_run <= scenarioRun.scenarios[scenarioId].mp_compressors_running ? ` ${theme.palette.success.main} ` : ` ${theme.palette.secondary.main} ` }}>{scenarioRun.scenarios[scenarioId].mp_compressors_running}MP</span>
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow >
                                    <StyledTableCell style={{ fontWeight: 'bold' }}>Day to reach top tank &nbsp;
                                        {props.excludedTanks.length > 0 &&
                                            <><span style={{ color: theme.palette.secondary.main }}>Without {props.excludedTanks.substring(0, props.excludedTanks.length)}</span></>
                                        }
                                    </StyledTableCell>
                                    <StyledTableCell style={{ background: 'yellow', fontWeight: 'bold' }} >{formatShortDateString(scenarioRun.scenarios[scenarioId].day_to_reach_top_tank , true)}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow >
                                    <StyledTableCell style={{ fontWeight: 'bold' }}>Autonomy (days) &nbsp;
                                        {props.excludedTanks.length > 0 &&
                                            <><span style={{ color: theme.palette.secondary.main }}>Without {props.excludedTanks.substring(0, props.excludedTanks.length)}</span></>
                                        }
                                    </StyledTableCell>
                                    <StyledTableCell className='tb' style={{ fontWeight: 'bold' }} >{Math.floor(scenarioRun.scenarios[scenarioId].autonomy_hours / 24)} days {scenarioRun.scenarios[scenarioId].autonomy_hours % 24 > 0 ? scenarioRun.scenarios[scenarioId].autonomy_hours % 24 + ' hours' : ''} </StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </Paper>
                </Box>
                <Box p={'.5rem'} sx={{ gridArea: 'b' }}>
                    <StorageProfile includedTanks={props.includedTanks} displayStorageProfileBarChart={props.displayStorageProfileBarChart} setDisplayStorageProfileBarChart={props.setDisplayStorageProfileBarChart} />
                </Box>
                <Box p={'.5rem'} sx={{ gridArea: 'c' }}>
                    <Paper>
                        <Table>
                            <TableHead >
                                <StyledTableRow><StyledTableCell colSpan={4} sx={{ background: 'white', color: `${theme.palette.secondary.main}`, fontWeight: 'bold' }} >Condensate Spike Profiles</StyledTableCell></StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell > Start Date </StyledTableCell>
                                    <StyledTableCell > Duration (hrs)</StyledTableCell>
                                    <StyledTableCell > Amount (bbls/day)</StyledTableCell>


                                </StyledTableRow>
                            </TableHead>
                            <TableBody style={{ fontSize: '12px' }}>
                                {scenarioRun.scenarios[scenarioId].condensate_spike_profiles.map((item, index) => {
                                    return (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell >{formatDateTimeLocal(item.start_date_time)} </StyledTableCell>
                                            <StyledTableCell >{item.duration_hours}</StyledTableCell>
                                            <StyledTableCell >{item.spike_rate_bbls_per_day}</StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })}
                                {scenarioRun.scenarios[scenarioId].condensate_spike_profiles.length === 0 ? <tr>
                                    <StyledTableCell colSpan={4}><p className=' text-center'> No record available...</p></StyledTableCell>


                                </tr> : ''}
                            </TableBody>
                        </Table>
                    </Paper>
                    <br />

                    <Paper>
                        <Table>
                            <TableHead >
                                <StyledTableRow><StyledTableCell colSpan={4} sx={{ background: 'white', color: `${theme.palette.secondary.main}`, fontWeight: 'bold' }} > Oil Re-injection Profiles</StyledTableCell></StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell > Start Date </StyledTableCell>
                                    <StyledTableCell > Duration (hrs)</StyledTableCell>
                                    <StyledTableCell > Amount (bbls/day)</StyledTableCell>


                                </StyledTableRow>
                            </TableHead>
                            <TableBody style={{ fontSize: '12px' }}>
                                {scenarioRun.scenarios[scenarioId].oil_reinjection_profiles.map((item, index) => {
                                    return (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell >{formatDateTimeLocal(item.start_date_time)} </StyledTableCell>
                                            <StyledTableCell >{item.duration_hours}</StyledTableCell>
                                            <StyledTableCell >{item.injection_rate_bbls_per_day}</StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })}
                                {scenarioRun.scenarios[scenarioId].oil_reinjection_profiles.length === 0 ? <tr>
                                    <StyledTableCell colSpan={4}><p className=' text-center'> No record available...</p></StyledTableCell>


                                </tr> : ''}
                            </TableBody>
                        </Table>
                    </Paper>
                </Box>


                <Box p={'.5rem'} sx={{ gridArea: 'd' }}>
                    <Paper>
                        <div></div>
                        <Table>
                            <TableHead >
                                <StyledTableRow>
                                    <StyledTableCell colSpan={8} sx={{ background: 'white', color: `${theme.palette.secondary.main}`, fontWeight: 'bold' }} >
                                        <div className='flex'>
                                            <span><img style={{ height: '16px' }} src='../../assets/images/oil.png' alt="logo.jpeg" /></span> &nbsp;&nbsp;<span>Oil Wells</span>
                                        </div>
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell sx={{ width: '5px' }}>SN</StyledTableCell>
                                    <StyledTableCell sx={{ width: '15px' }}>Wells</StyledTableCell>
                                    <StyledTableCell sx={{ width: '15px' }}>Header</StyledTableCell>
                                    <StyledTableCell sx={{ width: '15px' }}>Status</StyledTableCell>
                                    <StyledTableCell><WaterDrop style={{ color: `${theme.palette.success.main}`, fontSize: '16px' }} />Oil <br />(BOPD)</StyledTableCell>
                                    <StyledTableCell> <WaterDrop style={{ color: `${theme.palette.info.main}`, fontSize: '16px' }} />  Water <br /> (BWPD)</StyledTableCell>
                                    <StyledTableCell><WaterDrop style={{ color: `${theme.palette.warning.main}`, fontSize: '16px' }} />Gas<br /> (SCMD)</StyledTableCell>
                                    <StyledTableCell><WaterDrop style={{ color: `${theme.palette.warning.main}`, fontSize: '16px' }} />Gas Lift<br />(SCMD)​</StyledTableCell>

                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {currentPageRecords.map((item, index) => {
                                    return <StyledTableRow key={index}>
                                        <StyledTableCell sx={{ width: '5px' }}>{index + 1 + ((currentPage - 1) * recordsPerPage)}</StyledTableCell>
                                        <StyledTableCell sx={{ width: '15px' }}>{item.name}</StyledTableCell>
                                        <StyledTableCell sx={{ width: '15px' }}>{item.header}</StyledTableCell>
                                        <StyledTableCell sx={{ width: '15px', background: `${['O', 'AO'].includes(scenarioRun.scenarios[scenarioId].well_statuses[item.name]?.toUpperCase()) ? ` ${theme.palette.success.main} ` : ` ${theme.palette.secondary.main} `}` }}
                                        >{scenarioRun.scenarios[scenarioId].well_statuses[item.name]}</StyledTableCell>
                                        <StyledTableCell >{item.oil}</StyledTableCell>
                                        <StyledTableCell >{item.water}</StyledTableCell>
                                        <StyledTableCell >{item.gas}</StyledTableCell>
                                        <StyledTableCell >{item.gas_lift}</StyledTableCell>
                                    </StyledTableRow>
                                })}
                                {currentPageRecords.length === 0 &&
                                    <StyledTableRow>
                                        <StyledTableCell colSpan={7}><p style={{ fontSize: '11px', textAlign: 'center' }}>No record available...</p></StyledTableCell>
                                    </StyledTableRow>
                                }
                            </TableBody>
                        </Table>
                        <br />
                        <nav>
                            <div className=" flex flex-row ">
                                <div className=" flex items-start ">
                                    <div className="px-6 py-2">Rows per page</div>&nbsp;
                                    < Grid item xs={12} lg={6} >
                                        <CustomTextField fullWidth id="fullWidth" select variant="outlined" size="small" name="RequestsNumbers" value={recordsPerPage} onChange={(e) => setRecordsPerPage(Number(e.target.value))} >

                                            <MenuItem value="60">60</MenuItem >
                                            <MenuItem value="120">120</MenuItem >
                                            <MenuItem value="200">200</MenuItem >
                                            <MenuItem value="255">255</MenuItem >
                                            <MenuItem value="350">350</MenuItem >
                                            <MenuItem value="500">500</MenuItem >


                                        </CustomTextField>
                                    </Grid>
                                </div>
                                <div style={{ flexGrow: 1 }} />
                                <Stack spacing={2}>
                                    <Pagination color="secondary" count={numOfPages} siblingCount={1} boundaryCount={1}
                                        onChange={(event, value) => setCurrentPage(value)} page={currentPage} size="small" classes={{ ul: "PaginationUl" }} />
                                </Stack>

                            </div>
                            <br />


                        </nav>
                    </Paper>
                </Box>

                <Box p={'.5rem'} sx={{ gridArea: 'e' }}>
                    <Paper>

                        <Table>
                            <TableHead >
                                <StyledTableRow >
                                    <StyledTableCell colSpan={7} sx={{ background: 'white', color: `${theme.palette.secondary.main}`, fontWeight: 'bold' }} >
                                        <div className='flex'>
                                            <span><img style={{ height: '16px' }} src='../../assets/images/gas.png' alt="logo.jpeg" /></span> &nbsp;&nbsp;<span>Gas Wells</span>
                                        </div>
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell sx={{ width: '5px' }}>SN</StyledTableCell>
                                    <StyledTableCell sx={{ width: '15px' }}>Wells</StyledTableCell>
                                    <StyledTableCell sx={{ width: '15px' }}>Header</StyledTableCell>
                                    <StyledTableCell sx={{ width: '15px' }}>Status</StyledTableCell>
                                    <StyledTableCell> <WaterDrop style={{ color: `${theme.palette.warning.main}`, fontSize: '16px' }} /> Qcond<br />(BCPD)</StyledTableCell>
                                    <StyledTableCell> <WaterDrop style={{ color: `${theme.palette.warning.main}`, fontSize: '16px' }} />Gas<br />(SCMD)</StyledTableCell>
                                    <StyledTableCell>MCA<br />(Y/N)</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {gascurrentPageRecords.map((item, index) => {
                                    return <StyledTableRow key={index}>
                                        <StyledTableCell sx={{ width: '5px' }}>{index + 1 + ((gascurrentPage - 1) * gasrecordsPerPage)}</StyledTableCell>
                                        <StyledTableCell sx={{ width: '15px' }}>{item.name}</StyledTableCell>
                                        <StyledTableCell sx={{ width: '15px' }}>{item.header}</StyledTableCell>
                                        <StyledTableCell sx={{ width: '15px', background: `${['O', 'AO'].includes(scenarioRun.scenarios[scenarioId].well_statuses[item.name]?.toUpperCase()) ? ` ${theme.palette.success.main} ` : ` ${theme.palette.secondary.main} `}` }}
                                        >{scenarioRun.scenarios[scenarioId].well_statuses[item.name]}</StyledTableCell>
                                        <StyledTableCell >{item.condensate}</StyledTableCell>
                                        <StyledTableCell >{item.gas}</StyledTableCell>
                                        <StyledTableCell >{item.mca}</StyledTableCell>
                                    </StyledTableRow>
                                })}
                                {gascurrentPageRecords.length === 0 &&
                                    <StyledTableRow>
                                        <StyledTableCell colSpan={7}><p style={{ fontSize: '11px', textAlign: 'center' }}>No record available...</p></StyledTableCell>
                                    </StyledTableRow>
                                }
                            </TableBody>
                        </Table>
                        <br />
                        <nav>
                            <div className=" flex flex-row ">
                                <div className=" flex items-start ">
                                    <div className="px-6 py-2">Rows per page</div>&nbsp;
                                    < Grid item xs={12} lg={6} >
                                        <CustomTextField fullWidth id="fullWidth" select variant="outlined" size="small" name="RequestsNumbers" value={gasrecordsPerPage} onChange={(e) => setGasRecordsPerPage(Number(e.target.value))} >

                                            <MenuItem value="60">60</MenuItem >
                                            <MenuItem value="120">120</MenuItem >
                                            <MenuItem value="200">200</MenuItem >
                                            <MenuItem value="255">255</MenuItem >
                                            <MenuItem value="350">350</MenuItem >
                                            <MenuItem value="500">500</MenuItem >


                                        </CustomTextField>
                                    </Grid>
                                </div>
                                <div style={{ flexGrow: 1 }} />
                                <Stack spacing={2}>
                                    <Pagination color="secondary" count={gasnumOfPages} siblingCount={1} boundaryCount={1}
                                        onChange={(event, value) => setGasCurrentPage(value)} page={gascurrentPage} size="small" classes={{ ul: "PaginationUl" }} />
                                </Stack>

                            </div>
                            <br />


                        </nav>
                    </Paper>
                </Box>
            </Box>

            {openOilWells &&
                <OilWellsKPI openOilWells={openOilWells} setOpenOilWells={setOpenOilWells} updateScenario={updateScenario} />
            }
            {openGasWells &&
                <GasWellsKPI openGasWells={openGasWells} setOpenGasWells={setOpenGasWells} updateScenario={updateScenario} />
            }
            {openOilReInjection &&
                <OilReinjectionKPI openOilReInjection={openOilReInjection} setOpenOilReInjection={setOpenOilReInjection} updateScenario={updateScenario} />
            }
            {openCondensateProfile &&
                <CondensateSpikeKPI openCondensateProfile={openCondensateProfile} setOpenCondensateProfile={setOpenCondensateProfile} updateScenario={updateScenario} />
            }
        </>
    )
}

export default memo(ScenarioDetails)