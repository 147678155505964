import React, { useEffect, useState } from 'react'
import { Box, Paper, Table, TableBody, TableContainer, TableHead, useTheme } from '@mui/material';
import { StyledTableCell, StyledTableRow } from '../layouts/theme';
import { store } from '../store/store';
import { formatShortDateString } from '../helpers/utils';
import { useParams, useSearchParams } from 'react-router-dom';
import { emptyScenarioRun } from '../helpers/constants';
import { ScenarioRun, Tank } from '../helpers/interfaces';
import { getAScenarioRun } from '../apiServices/ScenarioRunApi';
import { getTank } from '../apiServices/TankManagemetApi';
import ExecutiveSummaryStorageProfile from '../components/scenarioRun/ExecutiveSummaryStorageProfile';

interface Props { }

function ExecutiveSummary(props: Props) {
    const { id } = useParams();
    const appConfig = store.getState().appConfig.value;
    const theme = useTheme();
    const [scenarioRun, setScenarioRun] = useState<ScenarioRun>(emptyScenarioRun)
    const [includedTanks, setIncludedTanks] = useState<Tank[]>([]);
    const [excludedTanks, setExcludedTanks] = useState<string>('');
    const [tanks, setTanks] = useState<Tank[]>([]);
    const [searchParams, ] = useSearchParams();


    useEffect(() => {
        if (!id) return
        getAScenarioRun(id).then(_scenarioRun => {
            let hiddenScenarioID = searchParams.get("scenarioId")?.split(',')
           // const _scenarios = Object.entries(_scenarioRun.scenarios).filter(([id, scenario])=> hiddenScenarioID?.includes(id))
            let scenarios = _scenarioRun.scenarios
            Object.keys(_scenarioRun.scenarios).forEach((key) => {
            if(hiddenScenarioID?.includes(key)){
            scenarios = {...scenarios, [key]: {..._scenarioRun.scenarios[key], hidden: true}}
            }
            })
            console.log(scenarios)
            setScenarioRun({ ..._scenarioRun, scenarios: scenarios })

        })
        getTank().then(_tanks => {
            setTanks(_tanks)

        })
        
    }, [id, searchParams])
    useEffect(() => {
        const excluded_Tanks_arr = tanks.filter(e => scenarioRun.included_tank_ids.find(t => t === e.id) == null)
        const excluded_Tanks = excluded_Tanks_arr.map((e, i) => e.name)
        setExcludedTanks(excluded_Tanks.join(", ")
        )

        const includedTanks = tanks.filter(e => scenarioRun.included_tank_ids.find(t => t === e.id) != null)
        setIncludedTanks(includedTanks)
        
    }, [tanks, scenarioRun])
    return (
        excludedTanks &&
        <Box m="1.0rem 1.0rem">


            <div className=' font-bold text-lg' style={{ fontSize: '16px', color: theme.palette.text.secondary }}>{scenarioRun.description} &nbsp;-  Executive Summary</div>
            <Box>
                <Paper>
                    <Box mt="10px" p='1.0rem' gap="20px">
                        <TableContainer >
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                <TableHead >
                                    <StyledTableRow >
                                        <StyledTableCell className='tableBorder'>

                                        </StyledTableCell>
                                        {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                            return (
                                                <>
                                                    <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} key={'btnHdn' + id} style={{ minWidth: '200px', borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderTop: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderBottom: ' 1px solid #cdcdcd' }}>
                                                        {e.name}
                                                    </StyledTableCell>
                                                </>
                                            )
                                        })}

                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    <StyledTableRow  >
                                        <StyledTableCell  className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ maxWidth: '200px', fontWeight: 'bold', }} >Description</StyledTableCell>
                                        {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                            return <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', }}>{e.description}</StyledTableCell>
                                        })}
                                    </StyledTableRow>

                                    <StyledTableRow  >
                                        <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ fontWeight: 'bold', color: appConfig.DARK_MODE ? '#0288D1' : '#0288D1' }} >Gas Produced (MMSm3/d)</StyledTableCell>
                                        {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                            return <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', color: appConfig.DARK_MODE ? '#0288D1' : '#0288D1' }} >{((e.produced_gas/1000)?.toFixed(3))}</StyledTableCell>
                                        })}
                                    </StyledTableRow>
                                    <StyledTableRow  >
                                        <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ fontWeight: 'bold', color: appConfig.DARK_MODE ? '#0288D1' : '#0288D1' }} >Condensate Produced (bbls/d) </StyledTableCell>
                                        {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                            return <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', color: appConfig.DARK_MODE ? '#0288D1' : '#0288D1' }} >{parseFloat(e.produced_condensate?.toFixed(1))?.toLocaleString('en')}</StyledTableCell>
                                        })}
                                    </StyledTableRow>
                                    <StyledTableRow >

                                        <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} >Condensate Oil ratio </StyledTableCell>
                                        {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                            return <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{fontWeight: 'bold', borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', }} title={'Max COR  = ' + (scenarioRun.maximum_condensate_oil_ratio * 100) + '%'}>
                                                <span style={{ color: e.condensate_oil_ratio <= scenarioRun.maximum_condensate_oil_ratio ? `${theme.palette.success.main}` : `${theme.palette.secondary.main}` }}>
                                                    {(e.condensate_oil_ratio * 100)?.toFixed(2)}%
                                                </span>
                                            </StyledTableCell>
                                        })}
                                    </StyledTableRow>
                                    <StyledTableRow  >
                                        <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ fontWeight: 'bold', color: appConfig.DARK_MODE ? '#0288D1' : '#0288D1' }}> Oil Produced(bbls/d) </StyledTableCell>
                                        {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                            return <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', color: appConfig.DARK_MODE ? '#0288D1' : '#0288D1' }} >{parseFloat(e.produced_oil?.toFixed(1))?.toLocaleString('en')}</StyledTableCell>
                                        })}

                                    </StyledTableRow>
                                    <StyledTableRow  >
                                        <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ fontWeight: 'bold', color: appConfig.DARK_MODE ? '#0288D1' : '#0288D1' }}>Spike into OUR (bbls/d)</StyledTableCell>
                                        {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                            return <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', fontWeight: 'bold', color: appConfig.DARK_MODE ? '#0288D1' : '#0288D1' }} >{parseFloat(e.condensate_spike?.toFixed(1))?.toLocaleString('en')}</StyledTableCell>
                                        })}
                                    </StyledTableRow>
                                    <StyledTableRow  >
                                        <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ fontWeight: 'bold', color: appConfig.DARK_MODE ? '#0288D1' : '#0288D1' }}>Oil reinjected (bbls/d)</StyledTableCell>
                                        {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                            return <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', fontWeight: 'bold', color: appConfig.DARK_MODE ? '#0288D1' : '#0288D1' }} >{parseFloat(e.oil_reinjected?.toFixed(1))?.toLocaleString('en')}</StyledTableCell>
                                        })}
                                    </StyledTableRow>

                                    <StyledTableRow    >

                                        <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ fontWeight: 'bold' }} >HP compressor running</StyledTableCell>
                                        {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                            return <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ fontWeight: 'bold', borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', }} ><span style={{ color: scenarioRun.min_no_hp_compressors_to_run <= e.hp_compressors_running ? `${theme.palette.success.main}` : `${theme.palette.secondary.main}` }}>{e.hp_compressors_running}HP</span></StyledTableCell>
                                        })}
                                    </StyledTableRow>
                                    <StyledTableRow    >

                                        <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ fontWeight: 'bold' }}>MP+LP compressors running(MMsm3/d)</StyledTableCell>
                                        {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                            return <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ fontWeight: 'bold', borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', }} >
                                                <span style={{ fontWeight: 'medium', color: scenarioRun.min_no_lp_compressors_to_run <= e.lp_compressors_running ? `${theme.palette.success.main}` : `${theme.palette.secondary.main}` }}>{e.lp_compressors_running}LP</span>
                                                &nbsp;&nbsp;+ &nbsp;&nbsp;
                                                <span style={{ fontWeight: 'medium', color: scenarioRun.min_no_mp_compressors_to_run <= e.mp_compressors_running ? `${theme.palette.success.main}` : `${theme.palette.secondary.main}` }}>{e.mp_compressors_running}MP</span>
                                            </StyledTableCell>
                                        })}
                                    </StyledTableRow>
                                    <StyledTableRow  >

                                        <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ fontWeight: 'bold' }} >Day to reach top tank &nbsp;
                                            {excludedTanks.length > 0 &&
                                                <>
                                                    <span style={{ color: theme.palette.secondary.main }}>Without {excludedTanks.substring(0, excludedTanks.length)}</span>

                                                </>
                                            }
                                        </StyledTableCell>
                                        {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                            return <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ background: 'yellow', fontWeight: 'bold', borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', }} >{formatShortDateString(e.day_to_reach_top_tank, true)} </StyledTableCell>
                                        })}
                                    </StyledTableRow>
                                    <StyledTableRow  >
                                        <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ fontWeight: 'bold' }} >Autonomy (days) &nbsp;
                                            {excludedTanks.length > 0 &&
                                                <>
                                                    <span style={{ color: theme.palette.secondary.main }}>Without {excludedTanks.substring(0, excludedTanks.length)}</span>

                                                </>
                                            }
                                        </StyledTableCell>
                                        {Object.entries(scenarioRun.scenarios).filter(([id, scenario]) => !scenario.hidden).map(([id, e]) => {
                                            return <StyledTableCell className={scenarioRun.recommended_scenario_id === id ? '' : 'tableBorder'} style={{ fontWeight: 'bold', borderLeft: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderRight: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '', borderBottom: scenarioRun.recommended_scenario_id === id ? `2px  solid ${theme.palette.success.main} ` : '' }} >{Math.floor(e.autonomy_hours / 24)} days {e.autonomy_hours % 24 > 0 ? e.autonomy_hours % 24 + ' hours' : ''} </StyledTableCell>
                                        })}
                                    </StyledTableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Box>

                </Paper>
                <br />

                <ExecutiveSummaryStorageProfile includedTanks={includedTanks} scenarioRun={scenarioRun} />
            </Box>
        </Box>
    )
}

export default ExecutiveSummary
