
import { Button, Table, TableBody, TableHead, } from '@mui/material'
import { formatDateTimeLocal } from '../../helpers/utils';
import { emptyCondensateSpikeProfile, FormEditMode } from '../../helpers/constants';
import { Delete } from '@mui/icons-material';
import { CustomTextField, StyledTableCell, StyledTableRow } from '../../layouts/theme';
import { useEffect, memo } from 'react';
import { useScenarioContext } from '../../views/ViewScenarioRun';
import { Scenario } from '../../helpers/interfaces';
import { NumericFormat } from 'react-number-format';

interface Props {
    formEditMode: number
    privateScenario: Scenario
    setPrivateScenario: (scenario: Scenario) => void
}

function CondensateSpikeProfileDefinition(props: Props) {
    const { scenarioId, scenario, setScenario, scenarioRun,  } = useScenarioContext();
    useEffect(() => {
        if (props.formEditMode === FormEditMode.EDIT)
            props.setPrivateScenario(scenarioRun.scenarios[scenarioId])
        // eslint-disable-next-line
    }, [])

    const addNewCondensateSpike = () => {
        var next_available_date_hour: Date | null = null
        if ( props.privateScenario.condensate_spike_profiles.length === 0) {
            next_available_date_hour = new Date()
            next_available_date_hour.setMinutes(0, 0, 0)
        }
        else {
            const lastest_profile = props.privateScenario.condensate_spike_profiles[props.privateScenario.condensate_spike_profiles.length - 1]
            next_available_date_hour = new Date(lastest_profile.start_date_time)
            next_available_date_hour.setTime(next_available_date_hour.getTime() + lastest_profile.duration_hours * 60 * 60 * 1000)
        }
        props.setPrivateScenario({ ...props.privateScenario, condensate_spike_profiles: [...props.privateScenario.condensate_spike_profiles, { ...emptyCondensateSpikeProfile, start_date_time: next_available_date_hour }] })
        if (props.formEditMode !== FormEditMode.EDIT)
            setScenario({ ...scenario, condensate_spike_profiles: [...scenario.condensate_spike_profiles, { ...emptyCondensateSpikeProfile, start_date_time: next_available_date_hour }] })
    }

    const updateCondensateSpike = (start_date_time: Date, name: string, value: any) => {
        if (name === 'start_date_time')
            value.setMinutes(0, 0, 0)
        else if (name === 'duration_hours') {
            if (value < 1) return
            value = parseInt(value)
        }
        else if (name === 'spike_rate_bbls_per_day') {
            if (value <= 0) return
        }
        props.setPrivateScenario({ ...props.privateScenario, condensate_spike_profiles: props.privateScenario.condensate_spike_profiles.map(inst => inst.start_date_time !== start_date_time ? inst : { ...inst, [name]: value }) })
        if (props.formEditMode !== FormEditMode.EDIT)
            setScenario({ ...scenario, condensate_spike_profiles: scenario.condensate_spike_profiles.map(inst => inst.start_date_time !== start_date_time ? inst : { ...inst, [name]: value }) })
    }

    const deleteCondensateSpike = (start_date_time: Date) => {
        props.setPrivateScenario({ ...props.privateScenario, condensate_spike_profiles: props.privateScenario.condensate_spike_profiles.filter(inst => inst.start_date_time !== start_date_time) })
        if (props.formEditMode !== FormEditMode.EDIT)
            props.setPrivateScenario({ ...scenario, condensate_spike_profiles: scenario.condensate_spike_profiles.filter(inst => inst.start_date_time !== start_date_time) })
    }


    return (
        <div>
            <span style={{ float: 'right' }}>
                <Button onClick={addNewCondensateSpike} variant='outlined' color='secondary' >Add Condensate Spike Event</Button>
            </span>
            <br />
            <br />
            <div className="relative overflow-x-auto ">
                <Table>
                    <TableHead >
                        <StyledTableRow><StyledTableCell colSpan={4} scope="col" >Condensate Spike Profiles</StyledTableCell></StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell > Start Date </StyledTableCell>
                            <StyledTableCell > Duration(hrs)</StyledTableCell>
                            <StyledTableCell> Amount(bbls/day)</StyledTableCell>
                            <StyledTableCell > Actions</StyledTableCell>

                        </StyledTableRow>
                    </TableHead>
                    <TableBody style={{ fontSize: '12px' }}>
                        {props.privateScenario.condensate_spike_profiles.map((item, index) => {
                            return (
                                <StyledTableRow key={index}>
                                    <StyledTableCell>  <CustomTextField disabled={props.privateScenario.condensate_spike_profiles.length !== 1} size="small" type='datetime-local' onChange={(e) => updateCondensateSpike(item.start_date_time, e.target.id, new Date(e.target.value))} id="start_date_time" value={formatDateTimeLocal(item.start_date_time)} /></StyledTableCell>
                                    <StyledTableCell><NumericFormat  size="small" customInput={CustomTextField}  disabled={index !== props.privateScenario.condensate_spike_profiles.length - 1}  id='duration_hours' value={item.duration_hours} onChange={(e: { target: { id: string; value: any; }; }) => updateCondensateSpike(item.start_date_time, e.target.id, e.target.value)} /></StyledTableCell>
                                    <StyledTableCell><NumericFormat  size="small" customInput={CustomTextField}  id='spike_rate_bbls_per_day' value={item.spike_rate_bbls_per_day} onChange={(e: { target: { id: string; value: any; }; }) => updateCondensateSpike(item.start_date_time, e.target.id, e.target.value)} /></StyledTableCell>
                                    <StyledTableCell>
                                        {index === props.privateScenario.condensate_spike_profiles.length - 1 &&
                                            <Button variant='contained' color='secondary' size='small' onClick={() => { deleteCondensateSpike(item.start_date_time) }}   > <Delete /></Button>
                                        }
                                    </StyledTableCell></StyledTableRow>
                            )
                        })}
                        {props.privateScenario.condensate_spike_profiles.length === 0 ? <StyledTableRow>
                            <StyledTableCell colSpan={4}><p className=' text-center'> No record available...</p></StyledTableCell>


                        </StyledTableRow> : ''}
                    </TableBody>
                </Table>
            </div>
        </div>
    )
}

export default memo(CondensateSpikeProfileDefinition)