import { enqueueSnackbar } from "notistack";
import { Scenario, ServerAPIResponse ,ScenarioRun, ScenarionNameDescriptionDto, UpdateScenarioDto} from "../helpers/interfaces";
import { callServerAPI, showBackDrop } from "../helpers/utils";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);


export async function generateADHOCScenario(id: string, scenarioData: Scenario) {
    return await new Promise<ScenarioRun>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Creating new Adhoc Scenario "));
        callServerAPI<ServerAPIResponse<ScenarioRun>>('POST', `/api/scenario-run/generate-adhoc-scenario?scenario_run_id=${id}`, scenarioData, true )
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Adhoc Scenario Created", { variant: 'success' });
                resolve(response.data.data)
            })
    })
}

export async function generateOPTIMALScenario(id: string, scenarioData: Scenario) {
    return await new Promise<ScenarioRun>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Creating new Optimal Scenario "));
        callServerAPI<ServerAPIResponse<ScenarioRun>>('POST', `/api/scenario-run/generate-optimal-scenario?scenario_run_id=${id}`, scenarioData, true )
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Optimal Scenario Created", { variant: 'success' });
                resolve(response.data.data)
            })
    })
}

export const refreshScenarioRun = async (id: string) => {
    return await new Promise<ScenarioRun>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Refreshing Scenario Run "));
        callServerAPI<ServerAPIResponse<ScenarioRun>>('PATCH', `/api/scenario-run/referesh-scenario-run?scenario_run_id=${id}`, null, true)
            .then(response => {
                MySwal.close()
                resolve(response.data.data)
            })
    })
}

export const exportScenarioRun = async (id: string) => {
    return await new Promise<string>(async (resolve, reject) => {
     MySwal.fire(showBackDrop(" Exporting Scenario Run "));
        callServerAPI<ServerAPIResponse<string>>('GET', `/api/scenario-run/export-scenario-run?scenario_run_id=${id}`, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar(" Scenario Run Exported", { variant: 'success' });
                resolve(response.data.data)
                })
            })
}

export const UpdateScenarioNameDescription = async (scenario_run_id: string, scenario_id: string, nameDescriptionDto: ScenarionNameDescriptionDto) => {
    MySwal.fire(showBackDrop(" Updating Scenario  "));
       callServerAPI<ServerAPIResponse<ScenarioRun>>('PATCH', `/api/scenario-run/update-scenario-name-description?scenario_run_id=${scenario_run_id}&scenario_id=${scenario_id}`, nameDescriptionDto, true)
           .then(response => {
               MySwal.close()
               enqueueSnackbar(" Scenario  Updated", { variant: 'success' });
           })

}

export async function deleteScenario(scenario_run_id: string, scenario_id: string) {
    return await new Promise<void>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Deleting Scenario"));
        await callServerAPI<any>('DELETE', `/api/scenario-run/delete-scenario?scenario_run_id=${scenario_run_id}&scenario_id=${scenario_id}`, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Scenario Deleted", { variant: 'success' });
                resolve()
            })
    })
}

export const flagRecommendedScenario = async (scenario_run_id: string, scenario_id: string) => {
    MySwal.fire(showBackDrop(" Recommending Scenario  "));
       callServerAPI<ServerAPIResponse<ScenarioRun>>('PATCH', `/api/scenario-run/flag-recommeded-scenario?scenario_run_id=${scenario_run_id}&scenario_id=${scenario_id}`, null, true)
           .then(response => {
               MySwal.close()
               enqueueSnackbar(" Scenario  Updated", { variant: 'success' });
           })

}
export const UpdateScenarioForAnalysis = async (scenario_run_id: string, scenario_id: string, scenarioAnalysisDto:UpdateScenarioDto) => {
    return await new Promise<ScenarioRun>(async (resolve, reject) => {
    MySwal.fire(showBackDrop(" Updating Scenario  "));
       callServerAPI<ServerAPIResponse<ScenarioRun>>('PATCH', `/api/scenario-run/update-scenario?scenario_run_id=${scenario_run_id}&scenario_id=${scenario_id}`, scenarioAnalysisDto, true)
           .then(response => {
               MySwal.close()
               enqueueSnackbar(" Scenario  Updated", { variant: 'success' });
               resolve(response.data.data)
           })
        })
}

export async function duplicateScenario(scenario_run_id: string, scenario_id: string) {
    return await new Promise<ScenarioRun>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Creating a Duplicate Scenario "));
        callServerAPI<ServerAPIResponse<ScenarioRun>>('PATCH', `/api/scenario-run/duplicate-scenario?scenario_run_id=${scenario_run_id}&scenario_id=${scenario_id}`, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Duplicate Scenario Created", { variant: 'success' });
                resolve(response.data.data)
            })
    })
}