import React, { useEffect, useRef, useState } from 'react'
import { CompressorSettings, Dictionary, ScenarioRun, ScenarioRunSummary, Tank } from '../../helpers/interfaces'
import CustomDialog from '../CustomDialogue'
import { getCompressorSettings } from '../../apiServices/CompressorSettingsApi'
import { getAllTankSummaries } from '../../apiServices/TankManagemetApi'
import { Button, Checkbox, MenuItem, } from '@mui/material'
import { Add } from '@mui/icons-material'
import { createScenarioRun, getAScenarioRun, updateScenarioRunParameters } from '../../apiServices/ScenarioRunApi'
import { formatDateTimeLocal } from '../../helpers/utils'
import { emptyCompressorSettings, emptyScenarioRun, FormEditMode, TankType } from '../../helpers/constants'
import { CustomTextField } from '../../layouts/theme'
import { NumericFormat } from 'react-number-format'



interface Props {
  setFormEditMode: React.Dispatch<React.SetStateAction<number>>
  formEditMode: number
  updateScenarioRun:(c:ScenarioRun)=> void
  scenarioRunToEdit: string
  getScenarioRunHistory: () => Promise<ScenarioRunSummary[]>
  setScenarioRunSummary: React.Dispatch<React.SetStateAction<ScenarioRunSummary[]>>
  scenarioRunSummary: ScenarioRunSummary[]
  openScenarioRunForm: boolean
  setOpenScenarioRunForm: React.Dispatch<React.SetStateAction<boolean>>
}

function CreateScenarioRun(props: Props) {
  const [scenarioRun, setScenarioRun] = useState<ScenarioRun>(emptyScenarioRun);
  const [tanks, setTanks] = useState<Tank[]>([])
  const [compressorSettings, setCompressorSettings] = useState<CompressorSettings>(emptyCompressorSettings)
  const [hpCompressors, setHpCompressors] = useState<number[]>([])
  const [mpCompressors, setMpCompressors] = useState<number[]>([])
  const [lpCompressors, setLpCompressors] = useState<number[]>([])
  const [selectedTanks, setSelectedTanks] = useState<string[]>([]);
  const [isChecked, setIsChecked] = useState(false);
  const [dummyTankLevel, setDummyTankLevel] = useState<Dictionary<string, number>>({});
const _max_condensate_ratio = useRef(65)


//Math.max(...hpCompressors)

  useEffect(() => {
    getCompressorSettings().then(settings => {
       setScenarioRun(previousScenarioRun =>({...previousScenarioRun, min_no_hp_compressors_to_run: settings.num_hp_compressors,  min_no_lp_compressors_to_run: settings.num_lp_compressors, min_no_mp_compressors_to_run: settings.num_mp_compressors}))
  
      setCompressorSettings(settings)
    })
    getAllTankSummaries().then(tanks => setTanks(tanks))


  }, [])
   useEffect(() => {
    
     if (props.formEditMode === FormEditMode.EDIT) {
       getAScenarioRun(props.scenarioRunToEdit).then(_scenarioRun =>{ 
        _max_condensate_ratio.current = Number(_scenarioRun.maximum_condensate_oil_ratio) *100
        setScenarioRun(_scenarioRun)})
       
       
     }
   }, [props.formEditMode, props.scenarioRunToEdit])

   useEffect(() => {
    if(props.formEditMode !== FormEditMode.EDIT) {
    let newSelectedTanks = tanks.map(e => e.id)
    setSelectedTanks(newSelectedTanks)
    setScenarioRun(previousScenarioRun => ({ ...previousScenarioRun, included_tank_ids: newSelectedTanks }))
    }
    
  }, [props.formEditMode, tanks])

  useEffect(() => {
    if(props.formEditMode === FormEditMode.EDIT) {
      setSelectedTanks(scenarioRun.included_tank_ids)
      setIsChecked(scenarioRun.test_run);
    setDummyTankLevel(scenarioRun.dummy_tank_levels)
   
    }
    
  }, [isChecked, props.formEditMode, scenarioRun.dummy_tank_levels, scenarioRun.included_tank_ids, scenarioRun.test_run, tanks])

  const minDate = new Date().toISOString().slice(0, 16); // Sets the minimum date to the current date and time

  useEffect(() => {
    let maxlp = compressorSettings?.num_lp_compressors
    let maxmp = compressorSettings?.num_mp_compressors
    let maxhp = compressorSettings?.num_hp_compressors
    const num_hp_compressors = Array.from({ length: maxhp }, (_, i) => i + 1)
    const num_lp_compressors = Array.from({ length: maxlp }, (_, i) => i + 1)
    const num_mp_compressors = Array.from({ length: maxmp }, (_, i) => i + 1)
  
    setHpCompressors(num_hp_compressors)
    setLpCompressors(num_lp_compressors)
    setMpCompressors(num_mp_compressors)
  }, [compressorSettings?.num_hp_compressors, compressorSettings?.num_lp_compressors, compressorSettings?.num_mp_compressors])

 

  const MAX_VAL = 100;
  const withValueCap = (inputObj: { value: any }) => {
    const { value } = inputObj;
    if (value <= MAX_VAL) return true;
    return false;
  };

  const updateFieldValue = (name: string, value: any) => {
    setScenarioRun(previousScenarioRun => ({ ...previousScenarioRun, [name]: value }))

  }
  const updateDummyFieldValue = (name: string, value: number) => {
    let newdummyTankLevel = { ...dummyTankLevel, [name]: value }

    setDummyTankLevel(newdummyTankLevel)
    setScenarioRun(previousScenarioRun => ({ ...previousScenarioRun, dummy_tank_levels: newdummyTankLevel }))
  }

  const handleOnChange = () => {
    let isTestRun = scenarioRun.test_run
    setIsChecked(!isTestRun);
    setScenarioRun(previousScenarioRun => ({ ...previousScenarioRun, test_run: !isTestRun }))
    console.log(scenarioRun.test_run)
  };

  function AddNewScenarioRun() {

    let newScenarioRun: ScenarioRun = { ...scenarioRun, implementation_start_date: scenarioRun.implementation_start_date, maximum_condensate_oil_ratio: (scenarioRun.maximum_condensate_oil_ratio) / 100 }

    createScenarioRun(newScenarioRun).then(scenarioRun => {

      props.setOpenScenarioRunForm(false);
      props.getScenarioRunHistory().then(e => props.setScenarioRunSummary(e.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())))
    })
  }

  function UpdateScenarioRun( ){
    let updatedScenarioRun: ScenarioRun = { ...scenarioRun, implementation_start_date: scenarioRun.implementation_start_date, maximum_condensate_oil_ratio: (scenarioRun.maximum_condensate_oil_ratio) / 100 }
    updateScenarioRunParameters( updatedScenarioRun, scenarioRun.id).then(
      _scenarioRun => {
        console.log(_scenarioRun)
        props.updateScenarioRun(_scenarioRun)
      }
    )
    
    props.setOpenScenarioRunForm(false);
   
  }

  const handleTankSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value
    let checked = event.target.checked
    console.log(value)

    if (checked === true) {
      let newSelectedTanks = [...selectedTanks, value]
      setSelectedTanks(newSelectedTanks)
      let _dummytankLevel = {...scenarioRun.dummy_tank_levels, [value]:0}
      setScenarioRun(previousScenarioRun => ({ ...previousScenarioRun, included_tank_ids: newSelectedTanks , dummy_tank_levels:_dummytankLevel }))
     
      console.log(newSelectedTanks)
    }
    else {
      let newSelectedTanks = selectedTanks.filter(e => e !== value)
      setSelectedTanks(newSelectedTanks)

     let _dummytankLevel = scenarioRun.dummy_tank_levels
     delete _dummytankLevel[value]
     setScenarioRun(previousScenarioRun => ({ ...previousScenarioRun, included_tank_ids: newSelectedTanks , dummy_tank_levels:_dummytankLevel }))
      console.log(scenarioRun.included_tank_ids)
    }
  }

  return (
    compressorSettings && tanks && hpCompressors &&
    <CustomDialog dialogTitle={props.formEditMode === FormEditMode.CREATE ? ' Add Scenario Run' : 'Update Scenario Run '} setOpenDialog={props.setOpenScenarioRunForm} openDialog={props.openScenarioRunForm}>

      <form>
        <div style={{ float: 'right' }}>

          <div id="test_run"><Checkbox color='secondary' value='true' checked={isChecked} onChange={handleOnChange} />&nbsp;Test Run</div>
        </div>
        <br />
        {!scenarioRun.test_run &&
          <div >
            <div >
              <label className=' title-label'>Select Tank </label>
            </div>
            <div className='flex border border-[#cbcbcb]'>
              {tanks.map((tank, index) => {
                return <div key={index} className='flex flex-row'>
                  <div title={tank.type === TankType.WASH_TANK ? 'Wash Tank' : 'Storage Tank'}><Checkbox color='secondary' value={tank.id} checked={selectedTanks.includes(tank.id)} onChange={handleTankSelection} />{tank.name}</div>
                </div>
              })}
            </div>
          </div>
        }
        {scenarioRun.test_run &&
          <div >
            <div >
              <label className=' title-label'>Select Tank </label>
            </div>
            
            <div style={{ display: 'flex' }}  className='p-2 border border-[#cbcbcb]'>
              <div style={{ flex: 1 }}>
                {tanks.slice(0, 2).map((tank, index) => {
                  return (
                    <div key={index} className='flex '>
                      <div className='flex flex-row'>
                        <div title={tank.type === TankType.WASH_TANK ? 'Wash Tank' : 'Storage Tank'}><Checkbox color='secondary' value={tank.id} checked={selectedTanks.includes(tank.id)} onChange={handleTankSelection} />{tank.name} </div>
                      </div>
                      &nbsp;  &nbsp;
                      {selectedTanks.includes(tank.id) &&
                        <div className='flex flex-row dummyColumn' >
                          <NumericFormat style={{ width: '100%' }} size="small" customInput={CustomTextField} id='dummyTankLevel' placeholder={tank.type === TankType.WASH_TANK ? 'Dummy Oil Thickness (m)' : 'Dummy Tank Level (%)'} value={dummyTankLevel[tank.id]} onChange={(e: { target: { value: any } }) => updateDummyFieldValue(tank.id, Number(e.target.value))} />
                          
                        </div>
                      }
                     
                    </div>
                  )
                })}
              </div>
              <div style={{ flex: 1 }}>
                {tanks.slice(2, 4).map((tank, index) => {
                  return (
                    <div key={index} className='flex'>
                      <div className='flex flex-row'>
                        <div title={tank.type === TankType.WASH_TANK ? 'Wash Tank' : 'Storage Tank'}><Checkbox color='secondary' value={tank.id} checked={selectedTanks.includes(tank.id)} onChange={handleTankSelection} />{tank.name} </div>
                      </div>
                      &nbsp;  &nbsp;
                      {selectedTanks.includes(tank.id) &&
                        <div className='flex flex-row dummyColumn' >
                          <NumericFormat style={{ width: '100%' }} size="small" customInput={CustomTextField} id='dummyTankLevel' placeholder="Dummy Tank Level" value={dummyTankLevel[tank.id]} onChange={(e: { target: { value: any } }) => updateDummyFieldValue(tank.id, Number(e.target.value))} />

                        </div>

                      }
                      
                    </div>
                  )
                })}
                
              </div>
            </div>
          </div>
        }
        <br />
        <div >
          <div >
            <label className='title-label'>Scenario Run Description​ <span className='important'>*</span></label>
          </div>

          <div className=''>

            <CustomTextField fullWidth size="small" id='description' placeholder="Scenario Run Description" value={scenarioRun.description} onChange={(e) => updateFieldValue('description', e.target.value)} />
          </div>
        </div>
        <br />

        <div className='row'>
          <div className='column1'>
            <div >
              <label className='title-label'>Min. No of LP Compressors to run​ <span className='important'>*</span></label>
            </div>

            <div className="form-group mb-0">
              <CustomTextField fullWidth size="small" select variant="outlined" id="min_no_lp_compressors_to_run" onChange={(e) => updateFieldValue('min_no_lp_compressors_to_run', e.target.value)} value={scenarioRun.min_no_lp_compressors_to_run}
              >
                {lpCompressors.map((lp, index) => {
                  return <MenuItem  key={index} value={lp}>{lp} LP</MenuItem >
                })}
              </CustomTextField>

            </div>
          </div>
          &nbsp;&nbsp;&nbsp;
          <div className='column2'>
            <div >
              <label className='title-label'>Min. No of MP Compressors to run​ <span className='important'>*</span></label>
            </div>
            <CustomTextField fullWidth id="min_no_mp_compressors_to_run" select variant="outlined" size="small" value={scenarioRun.min_no_mp_compressors_to_run} onChange={(e) => updateFieldValue('min_no_mp_compressors_to_run', e.target.value)} >
              {mpCompressors.map((mp, index) => {
                return <MenuItem  key={index + 1} value={mp}>{mp} MP</MenuItem >
              })}
            </CustomTextField>
          </div>
          &nbsp;&nbsp;&nbsp;
          <div className='column2'>
            <div >
              <div >
                <label className='title-label'>Min. No of HP Compressors to run​ <span className='important'>*</span></label>
              </div>
              <CustomTextField defaultValue={2} fullWidth id="min_no_hp_compressors_to_run" select variant="outlined" size="small" value={scenarioRun.min_no_hp_compressors_to_run} onChange={(e) => updateFieldValue('min_no_hp_compressors_to_run', e.target.value)} >
                {hpCompressors.map((hp, index) => {
                  return <MenuItem  key={index + 1} value={hp}>{hp} HP</MenuItem >
                })}
              </CustomTextField>
            </div>
          </div>


        </div>
        <br />
        <div className='row'>
          <div className='column1'>
            <div >
              <div >
                <label className=' title-label'>Maximum Condensate Oil Ratio (%) </label>
              </div>
              <div className=''>
                <NumericFormat isAllowed={withValueCap} size="small" customInput={CustomTextField} id='maximum_condensate_oil_ratio' placeholder="Maximum Condensate Oil Ratio" value={_max_condensate_ratio.current} onChange={(e: { target: { id: string; value: any } }) => updateFieldValue(e.target.id, e.target.value)} style={{ width: '100%' }} />
              </div>
            </div>
          </div>&nbsp;&nbsp;&nbsp;
          <div className='column1'>
            <div >
              <label className='title-label'>Implementation Start Date / Time</label>
            </div>
            <div className=''>
              <div className="form-group mb-0">
                <CustomTextField InputLabelProps={{ shrink: true, }} inputProps={{ min: scenarioRun.test_run === true ? null : minDate, }}
                  fullWidth size="small" type='datetime-local' onChange={(e) => updateFieldValue('implementation_start_date', new Date(e.target.value))} id="implementation_start_date" value={formatDateTimeLocal(scenarioRun.implementation_start_date)} />
              </div>
            </div>
          </div>
        </div>
        <div className="form-group mt-3 mb-5" style={{ float: 'right' }}>
          <Button variant="contained" color='secondary' onClick={props.formEditMode === FormEditMode.CREATE ? AddNewScenarioRun : UpdateScenarioRun} >
            <Add />&nbsp; {props.formEditMode === FormEditMode.CREATE ? ' Add Scenario Run' : 'Update Scenario Run '}
          </Button>
        </div>
        <br />
      </form>
    </CustomDialog>
  )
}

export default CreateScenarioRun