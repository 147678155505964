
import { enqueueSnackbar } from "notistack";
import { ServerAPIResponse, ScenarioRunSummary, ScenarioRun } from "../helpers/interfaces";
import { showBackDrop, callServerAPI } from '../helpers/utils'
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);



export const getScenarioRunHistory = async () => {
    return await new Promise<ScenarioRunSummary[]>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Loading Scenario Run History"));
        callServerAPI<ServerAPIResponse<ScenarioRunSummary[]>>('GET', '/api/scenario-run/get-scenario-run-history', null, true)
            .then(response => {
                MySwal.close()
                resolve(response.data.data)
            })
    })
}
export const getAScenarioRun = async (id: string) => {
    return await new Promise<ScenarioRun>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Loading Scenario Run "));
        callServerAPI<ServerAPIResponse<ScenarioRun>>('GET', `/api/scenario-run/get-scenario-run?scenario_run_id=${id}`, null, true)
            .then(response => {
                MySwal.close()
                resolve(response.data.data)
            })
    })
}


export async function createScenarioRun(scenarioRunData: ScenarioRun) {
    return await new Promise<ScenarioRun>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Creating new Scenario Run"));
        callServerAPI<ServerAPIResponse<ScenarioRun>>('POST', '/api/scenario-run/create-scenario-run', scenarioRunData, true )
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Scenario Run Created", { variant: 'success' });
                resolve(response.data.data)
            })
    })
}

export async function deleteScenarioRun(id: string) {
    return await new Promise<void>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Deleting Scenario Run"));
        await callServerAPI<any>('DELETE', `/api/scenario-run/delete-scenario-run?scenario_run_id=${id}`, null, true)
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Scenario Run Deleted", { variant: 'success' });
                resolve()
            })
    })
}

export async function updateScenarioRunParameters(scenarioRunData: ScenarioRun,scenario_run_id : string) {
    return await new Promise<ScenarioRun>(async (resolve, reject) => {
        MySwal.fire(showBackDrop("Updating  Scenario Run"));
        callServerAPI<ServerAPIResponse<ScenarioRun>>('POST', `/api/scenario-run/update-scenario-run-parameters?scenario_run_id=${scenario_run_id}`, scenarioRunData, true )
            .then(response => {
                MySwal.close()
                enqueueSnackbar("Scenario Run Updated", { variant: 'success' });
                resolve(response.data.data)
            })
    })
}