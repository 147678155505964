import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';


interface BarChartProps {
    datasets: {
        label: string;
        data: number[];
        borderColor: string;
    }[];
    labels: string[];

}

const BarChart: React.FC<BarChartProps> = ({ datasets, labels, }) => {
    const chartContainer = useRef<HTMLCanvasElement>(null);
    const chartInstance = useRef<Chart>();

    useEffect(() => {
        if (chartContainer && chartContainer.current) {
            const ctx = chartContainer.current.getContext('2d');
            if (ctx) {
                chartInstance.current = new Chart(ctx, {
                    type: 'bar',
                    data: {
                        labels: labels,
                        datasets: datasets.map(dataset => ({
                            label: dataset.label,
                            data: dataset.data,
                            borderColor: dataset.borderColor,
                            backgroundColor:dataset.borderColor,
                            borderWidth:2,
                            fill: false
                        }))
                    },
                    options: {
                        responsive: true,
              
                        plugins: {
                            title: {
                                display: true,
                                text: ''
                            }
                        },
                        scales: {
                            x: {
                                title: {
                                    display: true,
                                    text: 'Days'
                                }
                            },
                            y: {
                                title: {
                                    display: true,
                                    text: 'Storage Spare capacity(bbls)'
                                }
                            }
                        }
                    }
                });
            }
        }

        // Clean up on component unmount
        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };
    }, [datasets, labels]);

    return <canvas ref={chartContainer} />;
};

export default BarChart;