import React, { useState } from 'react'
import { Typography, Toolbar, AppBar, Stack, Menu, MenuItem, Divider, ListItemIcon, Paper, IconButton, } from "@mui/material";
import { AccountCircle, Brightness4, Brightness7, Logout, } from "@mui/icons-material";
import { useAppSelector } from '../store/store';
import { useAppDispatch } from '../store/store';
import { callServerTepngUserLogout } from '../helpers/utils';
import Navbar from './Navbar';
import { setAppConfig } from '../store/appConfig';
import  {useTheme} from '@mui/material/styles';

function Header() {
  const dispatch = useAppDispatch();
  const appConfig = useAppSelector(state => state.appConfig.value);
  const serverGlobalSettings = useAppSelector(state => state.serverGlobalSettings.value);
  const userLoggedOn = useAppSelector(state => state.loggedOnUserProfile.value);
  const appBar: React.CSSProperties = {
    zIndex: 1300,
    height: 100,
    top: 0,
    border: 1,
    boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)'
  }

  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState<Element | null>(null);
  const logout = async () => {

    await callServerTepngUserLogout()
  }
  const theme = useTheme();
  return (
    <>
      <AppBar style={appBar}>
        <Toolbar >
          <div style={{ width: '280px' }}>
            <img style={{ height: '50px' }} src='../../assets/images/logo_totalenergies.png' alt="logo.jpeg" />
          </div>
          <Typography variant="subtitle1" > OML58 <em style={{color:theme.palette.secondary.main}}className=' font-bold' >PROP-TSC</em> &nbsp; (Production Optimisation against TNP Curtailment and Storage Constraints)   </Typography>
          <div style={{ flexGrow: 1 }} />

          <Typography variant="subtitle1" color="textPrimary">
            {userLoggedOn == null ? 'No User logged in' : `Welcome ${userLoggedOn.first_name}`}
          </Typography>
          <IconButton color="secondary" edge="end" title={userLoggedOn?.email} onClick={(event) => { setProfileMenuAnchorEl(event.currentTarget); setShowProfileMenu(previous => !previous) }}> <AccountCircle fontSize="large" /> </IconButton> &nbsp;&nbsp;&nbsp;&nbsp;
          <Typography variant="subtitle2" color="textPrimary" title={
            "Client Environment: " + appConfig.ENVIRONMENT +
            "\nServer Environment: " + serverGlobalSettings?.environment +
            "\nClient App Version: " + appConfig.APP_VERSION +
            "\nServer App Version: " + serverGlobalSettings?.app_version +
            "\nClient Last Updated: " + new Date(appConfig.APP_LAST_UPDATED) +
            "\nServer Last Updated: " + (serverGlobalSettings == null ? "" : new Date(serverGlobalSettings.app_last_updated))}>
            {appConfig.ENVIRONMENT.toUpperCase()}   &nbsp;&nbsp;&nbsp; </Typography> &nbsp;&nbsp;

          <IconButton onClick={() => dispatch(setAppConfig({ ...appConfig, DARK_MODE: !appConfig.DARK_MODE }))} color="secondary" title={appConfig.DARK_MODE ? 'Switch to Light Mode' : 'Switch to Dark Mode'}>
            {appConfig.DARK_MODE ? <Brightness7 /> : <Brightness4 />}
          </IconButton>
          <Menu style={{ zIndex: 1400 }} anchorEl={profileMenuAnchorEl} open={showProfileMenu} onClose={() => { setShowProfileMenu(false) }}>
            <MenuItem onClick={() => logout()}><ListItemIcon><Logout /></ListItemIcon>Logout</MenuItem>
          </Menu>
        </Toolbar>
        <Divider />
        <Stack >
          <Paper sx={{ height: '50px', borderBottomLeftRadius: '0px !important', borderBottomRightRadius: '0px', }}>
            <Toolbar>
              <div style={{ width: '280px' }} />
              <Navbar />

            </Toolbar>
          </Paper>
        </Stack>

      </AppBar>

    </>
  )
}

export default Header