import { CompressorSettings, CondensateSpikeProfile, OilReinjectionProfile, Scenario, ScenarioRun, Tank } from "./interfaces"
import { formatDateTimeLocalNearestHour } from "./utils"


export const FormEditMode = {
    CREATE: 1,
    EDIT: 2,
    VIEW: 3
}

export enum ScenarioRunDisplay {
    TEST ='Test',
    STORAGE_PROFILE = "Storage Profile",
    SCENARIO_DETAILS = "Scenario Details",
    SCENARIO_ANALYSIS = "Scenario Analysis",
    VIEW_SCENARIOS_SUMMARY_TABLE = "Scenario Run Summary Table",
    ADD_SCENARIO = "Add Scenario",
}

export enum RoutePathNames {
    LOGIN = "login",
    USERS = "users",
    TANKS = "tanks",
    WELLS = "wells",
    COMPRESSOR_SETTINGS = "compressor-settings",
    SCENARIO_RUN_HISTORY = "scenario-run-history",
    SCENARIO_RUN = "scenario-run",
    EXECUTIVE_SUMMARY = 'executive-summary'
}

export enum AppPlatform {
    ActiveDirectoryDomain = "ActiveDirectoryDomain",
    Azure = "Azure"
}

export enum GlobalRole {
    AppUser = "User",
    Admin = "Admin",
}


export enum WellHeader {
    LLP = "LLP",
    LP = "LP",
    MP = "MP",
    HP = "HP"
}


export enum Status {
    OPEN = "O",
    CLOSED = "C",
    ALWAYS_OPEN = "AO"
}


export enum ScenarioType {
    NORMAL = "NORMAL",
    ADHOC = "ADHOC",
    OPTIMAL = "OPTIMAL"
}


export enum TankType {
    STORAGE_TANK = "ST",
    WASH_TANK = "WT"
}

export enum WellType {
    OIL = "OIL",
    GAS = "GAS"
}

export const emptyCompressorSettings: CompressorSettings = {
    id: '',
    created_by: '',
    created_at: new Date(),
    updated_by: '',
    updated_at: new Date(),
    num_lp_compressors: 0,
    num_mp_compressors: 0,
    num_hp_compressors: 0,
    min_daily_gas_production_to_run_1_lp: 0,
    min_daily_gas_production_to_run_2_lp: 0,
    min_daily_gas_production_to_run_1_mp: 0,
    min_daily_gas_production_to_run_1_hp: 0,
    min_daily_gas_production_to_run_2_hp: 0
}


export const emptyTank: Tank = {
    id: '',
    name: "",
    type: TankType.STORAGE_TANK,
    capacity: 0,
    volume_per_height: 0,
    tank_level_pi_tag: "",
    tank_level_percent: 0,
    tank_level_refresh_date: new Date(),
    tank_level_history: [],
    oil_thickness_pi_tag:'',
    lah: 0,
    oil_thickness:0,
    max_oil_thickness: 0
}

export const emptyScenarioRun: ScenarioRun = {
    description: '',
    id: '',
    included_tank_ids: [],
    created_by: '',
    created_at: new Date(),
    updated_by: '',
    updated_at: new Date(),
    scenarios: {},
    wells: [],
    compressor_settings: emptyCompressorSettings,
    min_no_lp_compressors_to_run: 0,
    min_no_mp_compressors_to_run: 0,
    min_no_hp_compressors_to_run: 0,
    maximum_condensate_oil_ratio: 65,
    implementation_start_date: formatDateTimeLocalNearestHour(new Date()),
    storage_spare_capacity_at_start_date: 0,
    recommended_scenario_id: '',
    last_refresh_date: new Date(),
    runner_email: '',
    run_date: new Date(),
    test_run: false,
    dummy_tank_levels: {},
}

export const emptyScenario: Scenario = {
    description:'',
    name: '',
    well_statuses: {},
    oil_reinjection_profiles: [],
    condensate_spike_profiles: [],
    storage_profiles: [],
    produced_oil: 0,
    produced_condensate: 0,
    produced_gas: 0,
    condensate_spike: 0,
    oil_reinjected: 0,
    condensate_filling_rate: 0,
    condensate_oil_ratio: 0,
    total_liquid_filling_rate: 0,
    total_liquid_produced: 0,
    water_export_and_injection: 0,
    gas_for_lp_compressor: 0,
    gas_for_mp_compressor: 0,
    gas_for_hp_compressor: 0,
    storage_space_gain_or_lost: 0,
    lp_compressors_running: 0,
    mp_compressors_running: 0,
    hp_compressors_running: 0,
    autonomy_hours: 0,
    day_to_reach_top_tank: new Date(),
    min_autonomy_hours: 0,
    scenario_type: '',
    hidden: false,
    recommended:false,
    recommended_scenario_id:''

}

export const emptyCondensateSpikeProfile: CondensateSpikeProfile = {
    start_date_time: new Date(),
    duration_hours: 1,
    spike_rate_bbls_per_day: 1
}

export const emptyOilReinjectionProfile: OilReinjectionProfile = {
    start_date_time: new Date(),
    duration_hours: 1,
    injection_rate_bbls_per_day: 1
}
